import {dateFormat} from "@/util/date";

export default {
  computed: {
    fileBtnOptions(){
      let result = [];
      if (['add', 'edit'].includes(this.formType)) {
        result.push({
          label: "新增",
          emit: "headImport",
          type: "button",
          btnOptType: "import",
        });
      }
      return result
    },
    userBtnOptions(){
      let result = [];
      if (['add', 'edit'].includes(this.formType)) {
        result.push({
          label: "新增",
          emit: "head-add",
          type: "button",
          btnOptType: "add",
        });
        result.push({
          label: "移除",
          emit: "head-remove",
          type: "button",
          btnOptType: "remove",
        });
      }
      return result
    },
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.formType)) {
        // result.push({
        //   label: "保存",
        //   emit: "head-save",
        //   type: "button",
        //   btnOptType: "save",
        // });
        result.push({
          label: "保存并返回",
          emit: "head-saveBack",
          type: "button",
          btnOptType: "saveBack",
        });
      }
      result.push({
        label: "取消",
        emit: "head-cancel",
        type: "button",
        btnOptType: "cancel",
      });
      return result;
    },
  },
  data(){
    let that=this;
    return {
      rules: {
        userName: [{
          required: true,
          message: '请选择姓名',
          trigger: 'change'
        }],
        orgName: [{
          required: true,
          message: '请选择隶属组织',
          trigger: 'change'
        }],
        tripState: [{
          required: true,
          message: '请选择行程状态',
          trigger: 'change'
        }],
        startDate: [{
          required: true,
          message: '请选择出发日期',
          trigger: 'change'
        }],
        startPlace: [{
          required: true,
          message: '请选择出发地',
          trigger: 'change'
        }],
        endPlace: [{
          required: true,
          message: '请选择目的地',
          trigger: 'change'
        }],
      },
      formType: '',
      headTitle: '',
      dataForm: {

      },
      
      tableData: [],
      corePersonnelTableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },

     
      thirdPartyOrganizationTableOption: {
        index: true,
        indexLabel: "序号",
        menuWidth: 100,
        linklabel: "title",
        column: [{
          label: "人员名称",
          prop: "companyCode",
          align: "left",
          overHidden: true
        },
          {
            label: "所在组织部门",
            prop: "companyName",
            width: 200,
            align: "center",
            overHidden: true
          },
          {
            label: "角色",
            prop: "legalRep",
            width: 200,
            align: "center",
            overHidden: true
          },
          {
            label: "岗位",
            prop: "regLocation",
            width: 150,
            align: "center",
            overHidden: true
          },

        ],
      },

    }
  },
  methods: {
    filterTreeDataByAttr(data) {
      if (!data || typeof data !== "object") {
        return data;
      }

      if (Array.isArray(data)) {
        return data.map((item) => this.filterTreeDataByAttr(item)).filter(Boolean);
      }

      const { regionLevel, childList, ...rest } = data;

      if (regionLevel === 1||!regionLevel) {
        return {
          regionLevel,
          ...rest,
          ...(childList &&
          childList.length > 0 && {
            childList: this.filterTreeDataByAttr(childList),
            }),
        };
      }

      return null;
    },
  },
}
